import React from 'react'
import loadable from '@loadable/component'

const SECTION_MAP = {
  ContentfulBenefit: loadable(() => import('../benefit')),
  ContentfulGateway: loadable(() => import('../gateway')),
  ContentfulMenuCatgory: loadable(() => import('../menuCatgory')),
  ContentfulPhotos: loadable(() => import('../photos/index')),
  ContentfulText: loadable(() => import('../text/index'))
}

const SectionsRenderer = ({ sections=[], sectionMap={}, key='__typename' }) => {
  return sections.map((section, index) => {
    if (section[key] === undefined) return null

    const Component = SECTION_MAP[section[key]]
    return (
      <Component
        key={index}
        id={section.contentful_id}
        {...section}
        fallback={getFallback(section.contentful_id)}
      />
    )
  })
}

const getFallback = (id) => {
  if (typeof window === 'undefined') return null
  const element = window.document.getElementById(id)
  return element ? <section key={id} id={id} dangerouslySetInnerHTML={{ __html: element.innerHTML }}/> : null
}

// class SectionsRenderer extends Component {
//
//
//
//   Renderer
//   render() {
//     const { sections } = this.props;
//
//     if (!sections || sections.length === 0) {
//       return null;
//     }
//
//     const renderedSections = sections.map(section => {
//       const key = section.contentful_id;
//
//       switch (section.__typename) {
//         case 'ContentfulBenefit':
//           const body = ( section.body && section.body.body);
//           return <Benefit key={key} headline={section.headline} body={body} visual={section.visual}/>
//         case 'ContentfulGateway':
//           return <Gateway key={key} headline={section.headline} teaser={section?.teaser?.teaser} buttonLabel={section.buttonLabel} visual={section.visual} page={section.page}/>
//         case 'ContentfulMenuCatgory':
//           const description = (section.description && section.description.description);
//           return <MenuCatgoryComponent key={key} name={section.name} items={section.items} description={description}/>
//         case 'ContentfulPhotos':
//           return <Photos key={key} photos={section.photos} displayAsCarousel={section.displayAsCarousel} ratio={section.ratio} autoplayCarousel={section.autoplayCarousel} inContainer={section.inContainer} />
//         case 'ContentfulText':
//           return <TextComponent key={key} text={section.text.text} lead={section.lead}/>
//         default:
//           return null;
//       }
//     });
//
//     return renderedSections;
//   }
// }
//
// SectionsRenderer.propTypes = {
//   sections: PropTypes.array.isRequired
// }

export default SectionsRenderer

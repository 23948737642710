import React from "react"
import { graphql } from 'gatsby'

import Layout from '../components/layout/index'
import SectionsRenderer from '../components/sections/renderer'
import Seo from '../components/seo'
import { pathTo } from '../routes'

const Page = ({ data: { page: { title, slug, metaTitle, metaDescription, sharingTitle, sharingDescription, sharingImage, metaKeywords, sections} }}) => (
  <Layout>
    <Seo
      title={title}
      metaTitle={metaTitle}
      metaDescription={metaDescription?.metaDescription}
      sharingTitle={sharingTitle}
      sharingDescription={sharingDescription?.sharingDescription}
      sharingImage={sharingImage?.file?.url}
      currentUrl={pathTo({ __typename: 'ContentfulPage', slug })}
      keywords={metaKeywords}
    />
    <SectionsRenderer sections={sections}/>
  </Layout>
)

export default Page;

export const query = graphql`
  query PageQuery($slug: String) {
    page: contentfulPage(slug: { eq: $slug }) {
      __typename
      title
      slug
      metaTitle
      metaDescription {
        metaDescription
      }
      sharingTitle
      sharingDescription {
        sharingDescription
      }
      sharingImage {
        title
        description
        contentful_id
        file {
          url
        }
      }
      metaKeywords
      sections {
        __typename
        ... on ContentfulText {
          contentful_id
          text {
            text
          }
          lead
        }
        ... on ContentfulMenuCatgory {
          contentful_id
          name
          description {
            description
          }
          items {
            name
            description {
              description
            }
            price
          }
        }
        ... on ContentfulPhotos {
          ...Photos
        }
        ... on ContentfulBenefit {
          ...Benefit
        }
        ... on ContentfulGateway {
          ...Gateway
        }
      }
    }
  }
`
